const camelCasify = (str = "", regex) => str.replace(regex, (g) => g[1].toUpperCase())

export const shouldExcludeProgram = (slug, color, exclusions = {}) => {
  const camelCasedSlug = camelCasify(slug, /-([a-zA-Z0-9])/g)
  const excludedProgram = exclusions[camelCasedSlug]

  if (!excludedProgram) return false

  return Object.keys(excludedProgram).length > 0
    ? !!excludedProgram[camelCasify(color, / ([a-zA-Z0-9])/g)]
    : excludedProgram
}

export const shouldPromoTypeShowDiscount = (config) => {
  const { promoType, onSale, isFinalSale } = config
  switch (promoType) {
    case "On sale products":
      if (onSale && !isFinalSale) {
        return true
      }
      return false
    case "Final sale products":
      if (isFinalSale) {
        return true
      }
      return false
    case "Final and on sale products":
      if (onSale || isFinalSale) {
        return true
      }
      return false
    case "Sitewide":
      return true
    default:
      return false
  }
}

const bundleColors = {
  black: true,
  charcoal: true,
  grey: true,
  lightGrey: true,
  lightNavy: true,
  navy: true,
}

const bundleColorsExtendColors = {
  ...bundleColors,
  tan: true,
}
export const bundleSlugsExclusions = {
  // ICON SUIT BUNDLES
  jetsetterLightBlueWindowpane: bundleColors,
  jetsetterIcebergBlue: bundleColors,
  jetsetterDustyRose: bundleColors,
  jetsetterStretchWoolSuitJacketSuitg00145: bundleColors,
  jetsetterStretchWoolSuitPantSuitg00146: bundleColors,
  jetsetterNavyPinkWindowpane: bundleColors,
  jetsetterBrownBluePlaid: bundleColors,
  jetsetterStretchWoolSuitLightNavy: bundleColorsExtendColors,
  jetsetterStretchWoolSuitLightGrey: bundleColorsExtendColors,
  jetsetterStretchWoolSuitBlackFa17: bundleColorsExtendColors,
  jetsetterStretchWoolSuitCharcoal: bundleColorsExtendColors,
  jetsetterStretchWoolSuitGrey: bundleColorsExtendColors,
  jetsetterStretchWoolSuitNavy: bundleColorsExtendColors,
  jetsetterStretchWoolSuitGreyBlueGlenPlaid: bundleColorsExtendColors,
  jetsetterStretchWoolSuitLightNavyWindowpane: bundleColorsExtendColors,
  jetsetterStretchWoolSuitHeatherGreyPinstripe: bundleColorsExtendColors,
  jetsetterStretchWoolSuitBlueMicrocheck: bundleColorsExtendColors,
  jetsetterStretchWoolSuitGreyCheck: bundleColorsExtendColors,
  jetsetterStretchWoolSuitSteelBlueTexture: bundleColorsExtendColors,
  jetsetterStretchWoolSuitBrownHoundstooth: bundleColorsExtendColors,
  jetsetterStretchWoolSuitLightBlueHoundstooth: bundleColorsExtendColors,
  jetsetterStretchWoolSuitBlueGlenPlaid: bundleColorsExtendColors,
  // EXTENDED SIZING ICON SUIT BUNDLES
  jetsetterStretchWoolSuitGreyExtendedSizing: bundleColorsExtendColors,
  jetsetterStretchWoolSuitLightNavyExtendedSizing: bundleColorsExtendColors,
  jetsetterStretchWoolSuitBlackExtendedSizing: bundleColorsExtendColors,
  jetsetterStretchWoolSuitNavyExtendedSizing: bundleColorsExtendColors,
  jetsetterStretchWoolSuitCharcoalExtendedSizing: bundleColorsExtendColors,
  jetsetterStretchWoolSuitLightBlueMicroExtendedSizing: bundleColorsExtendColors,
  jetsetterStretchWoolSuitSawyerBurgundyGlenPlaidExtendedSizing: bundleColorsExtendColors,
}
